<template>
  <div class="shortlist-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-6 column">
              <div class="heading-profile">
                <h3>Inquire for Deals &amp; Offer(Hotels)</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right">
                      <form class="search-form mr-form">
                        <input
                          type="text "
                          class="form-control"
                          v-model="searchText"
                          @input="getAllEnquiriesTypeforInquiries(1)"
                          placeholder="Search Here..."
                        />
                      </form>
                    </div>
                    <div class="d-flex">
                      <div>
                        <h3>
                          List of Inquire for Deals &amp; Offer
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h3>
                      </div>
                      <div class=" ml-3 ">
                        <b-button variant="success" @click="generateExcelForOfferEnquiries(page)"
                          >Download</b-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>User Name</th>
                          <th>Date of Inquire</th>
                          <th>Check In</th>
                          <th>Check Out</th>
                          <th>Email Id</th>
                          <th>Phone No</th>
                          <th>Hotel Name</th>
                          <th>Offer Name</th>
                          <th colspan="2">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(alluser, index) in users" v-bind:key="alluser._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>
                          <!-- <td>1</td> -->

                          <td>
                            <div>{{ alluser.name }}</div>
                          </td>

                          <td>
                            <div v-if="alluser.enquiryCreatedAt">
                              {{ moment(alluser.enquiryCreatedAt).format("DD/MM/YYYY") }}
                            </div>
                          </td>
                          <td>
                            <div v-if="alluser.checkIn">
                              {{ moment(alluser.checkIn).format("DD/MM/YYYY") }}
                            </div>
                          </td>
                          <td>
                            <div v-if="alluser.checkOut">
                              {{ moment(alluser.checkOut).format("DD/MM/YYYY") }}
                            </div>
                          </td>
                          <td>
                            <div>{{ alluser.email }}</div>
                          </td>
                          <td>
                            <div>{{ alluser.phone }}</div>
                          </td>
                          <td>
                            <div v-if="alluser.hotel">{{ alluser.hotel.name }}</div>
                          </td>
                          <td>
                            <div v-if="alluser.offer">{{ alluser.offer.offer.name }}</div>
                          </td>
                          <td>
                            <router-link
                              class="btn btn-primary"
                              :to="'/offerDealsInquiryDetails/' + alluser._id"
                              append
                            >
                              <b-icon-eye></b-icon-eye>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md
                    font-weight-bold text-muted"
                    v-if="users.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="users.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="getAllEnquiriesTypeforInquiries(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import apiservice from "@/service/apiservice";
import moment from "moment";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      id: "",
      logs: {
        idToDelete: ""
      },
      currentPage: 1,
      searchText: "",
      users: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      data: "",
      leadThroughType: "Offers & Deals",
      email: "",
      moment: moment,
      selected: null
    };
  },
  created() {
    this.getAllEnquiriesTypeforInquiries(this.page);
  },
  methods: {
    getAllEnquiriesTypeforInquiries(pageNo) {
      this.users = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      this.formData.leadThroughType = this.leadThroughType;
      apiservice.getAllEnquiriesTypeforInquiries(this.formData, data => {
     
        if (data.code === 200) {
          this.users = data.data.result;
          this.totalCount = data.data.TotalCount;
        } else {
         
        }
      });
    },
    generateExcelForOfferEnquiries() {
      apiservice.generateExcelForOfferEnquiries(this.formData, data => {
        apiservice.downloadExcel(data, "OfferEnquiries_List");
      });
    }
  }
};
</script>

<style lang="scss">
.shortlist-section {
  .text {
    color: black;
  }
}
</style>
